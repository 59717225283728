import { Box } from "@material-ui/core"
import { FTLPageHeader, FTLSelect, FTLToolbar } from "ftl-uikit"

export const SystemSettingsPage = () => {
  const smsServiceItems = [
    { label: "Не выбрано", value: "" },
    { label: "SMSC", value: "smsc" },
  ]
  const pushServiceItems = [
    { label: "Не выбрано", value: "" },
    { label: "Firebase", value: "firebase" },
  ]

  return (
    <>
      <FTLPageHeader title="Параметры системы" />

      <Box width="280px" height="100%">
        <Box mt={6}>
          <FTLSelect
            // pla
            // size="small"

            label="Сервис для отправки смс-сообщений"
            options={smsServiceItems}
            // defaultValue={smsServiceItems[0]}
            // isClearable
            // isMulti
            // options={[{}]}
            // fullWidth
            // id="sms-service-select"
            // // defaultValue={smsServiceItems[0].value}
            // onChange={(e) => {
            //   setSmsValue(e.target.value as string)
            // }}
            // // value={smsValue}
            // items={smsServiceItems}
            // label="Сервис для отправки смс-сообщений"
          />
        </Box>
        <Box mt={6}>
          <FTLSelect
            options={pushServiceItems}
            label="Сервис для отправки push-сообщений"
            // isClearable

            defaultValue={pushServiceItems[0]}
          />
        </Box>
      </Box>
      {/* </Box> */}
      {/* <Box mt={6}>
        <FTLButton disabled={!smsValue && !pushValue}>Сохранить</FTLButton>
      </Box> */}
      <FTLToolbar />
    </>
  )
}

export default SystemSettingsPage
