import { basicNormalizeAll } from "./meta"
import { handleError } from "ftl-core"
import { RoleAPI } from "../../../repository"
import { FetchAllArgs, Role } from "../../../types/entities"
import { Dictionary } from "ftl-core"

export const fetchAllRoles = async ({
  items,
  setItems,
  setIsFetching,
  normalize = basicNormalizeAll,
  labelProp,
}: FetchAllArgs<Role>): Promise<Dictionary<string>> => {
  if (!items)
    try {
      const responseItems = (await RoleAPI.getAll(45000)).data.result.items
      return normalize({ items: responseItems, setItems, labelProp })
    } catch (error) {
      handleError({
        error,
        defaultError: "Произошла ошибка при получении данных ролей",
        setIsFetching,
      })
      return {}
    }
  else return Promise.resolve(items.labels)
}
