import { lazy, useCallback, Suspense } from "react"
//components
import { Box } from "@material-ui/core"
import {
  FTLBreadcrumbs,
  FTLLoader,
  FTLPageHeader,
  FTLTab,
  FTLTabs,
  FTLToolbar,
} from "ftl-uikit"
import { FTLSelect } from "ftl-uikit"
import { FTLTextField } from "ftl-uikit"

import {
  dialogService,
  snackbarService,
  useDetailPage,
  useTabs,
} from "ftl-core"

//other
import {
  ExternalServiceSchema,
  statusItems,
  initState,
  ExternalServiceDetailPageProps,
  listTitleToType,
} from "./model"
import { handleError } from "ftl-core"
import { ExternalService } from "../../types/entities"
import { ExternalServicesAPI } from "../../repository"
import { FTLDetailPage } from "ftl-dashboards-templates"
import { HistoryDetailState } from "ftl-core"

const MainSettingsTab = lazy(() => import("./layout/MainSettingsTab"))
const ConfigurationTab = lazy(() => import("./layout/ConfigurationTab"))
const AccountTab = lazy(() => import("./layout/AccountTab"))

export const DetailExternalServicesPage = ({
  pageType,
  type = "ORDER_SYNC",
}: ExternalServiceDetailPageProps) => {
  const baseUrl = window.location.pathname.split("/")[1]
  const {
    debouncedIsFetching,
    formik,
    history,
    isFormNotChanged,
    setIsFetching,
    isFetching,
    setEntityData,
  } = useDetailPage<
    ExternalService<"GET">,
    ExternalService<"FORMIK">,
    HistoryDetailState<ExternalService<"FORMIK">> & { tabIndex?: number }
  >({
    pageType,
    initialValues: { ...initState, type },
    validationSchema: ExternalServiceSchema,
    getDetails: ExternalServicesAPI.getById,
    handleFetchedEntity: (externalService) => {
      formik.setValues(externalService.data.result, false)
      setEntityData(externalService.data.result)
      setIsFetching(false)
    },
    onSubmit: async (values: ExternalService | ExternalService<"PATCH">) => {
      setIsFetching(true)
      formik.setSubmitting(true)
      try {
        switch (pageType) {
          case "new":
            const id = (
              await ExternalServicesAPI.create(values as ExternalService)
            ).data.result.id
            snackbarService.show({
              variant: "success",
              message: "Сервис успешно создан",
            })
            history.push(`/${baseUrl}/${id}`)
            break
          case "edit":
            await ExternalServicesAPI.edit(values as ExternalService<"PATCH">)
            snackbarService.show({
              variant: "success",
              message: "Параметры сервиса успешно обновлены",
            })
            history.push(`/${baseUrl}`)
            break
        }
        setEntityData(values)
      } catch (error) {
        handleError({
          error,
          defaultError: "Произошла ошибка при отправке данных",
        })
      } finally {
        formik.setSubmitting(false)
        setIsFetching(false)
      }
    },
  })

  const { tabIndex, setTabIndex } = useTabs(
    history.location.state?.tabIndex ?? 0,
    2
  )

  const setTabIndexCallback = useCallback(
    (newIndex: number) => {
      if (!isFormNotChanged && tabIndex === 0) {
        dialogService.show({
          onSubmit: () => {
            setTabIndex(newIndex)
            dialogService.close()
          },
          onCancel: undefined,
          submitText: "Продолжить",
          children:
            "Остались несохраненные изменения. Продолжить без изменения данных?",
        })
      } else setTabIndex(newIndex)
    },
    [isFormNotChanged, setTabIndex, tabIndex]
  )

  return (
    <FTLDetailPage<ExternalService<"FORMIK">>
      formik={formik}
      headerComponent={
        <>
          <FTLBreadcrumbs
            items={[
              { label: listTitleToType[type], to: `/${baseUrl}` },
              {
                label:
                  pageType === "new"
                    ? "Новый внешний сервис"
                    : "Параметры внешнего сервиса",
                to: "#",
              },
            ]}
          />

          <FTLPageHeader
            title={
              pageType === "new"
                ? "Новый внешний сервис"
                : "Параметры внешнего сервиса"
            }
            BoxProps={{
              mb: 6,
            }}
          />
        </>
      }
      toolbarComponent={
        pageType === "new" && (
          <FTLToolbar
            position="sticky"
            onSaveBtnId="form"
            onCancel={() => history.push(`/${baseUrl}`)}
            async={formik.isSubmitting}
            disabled={isFormNotChanged || formik.isSubmitting || isFetching}
            onSaveMessage={pageType === "new" ? "Создать" : "Сохранить"}
          />
        )
      }
    >
      {debouncedIsFetching || formik.isSubmitting || isFetching ? (
        <FTLLoader height="50vh" />
      ) : (
        <form
          noValidate
          id="form"
          onSubmit={(e) => {
            e.preventDefault()
            formik.handleSubmit()
          }}
        >
          {pageType === "new" ? (
            <Box display="grid" gridGap="24px" width="360px">
              <FTLTextField
                required
                name="name"
                label="Наименование"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={
                  formik.errors.name &&
                  formik.submitCount > 0 &&
                  formik.touched.name
                    ? true
                    : false
                }
                onBlur={formik.handleBlur}
                fullWidth
              />

              <FTLTextField
                required
                name="baseApiUrl"
                label="URL для получения конфигурации"
                value={formik.values.baseApiUrl}
                onChange={formik.handleChange}
                error={
                  formik.errors.baseApiUrl &&
                  formik.submitCount > 0 &&
                  formik.touched.baseApiUrl
                    ? true
                    : false
                }
                onBlur={formik.handleBlur}
                fullWidth
              />
              <Box maxWidth={168}>
                <FTLSelect
                  required
                  disableClearable
                  label="Активность"
                  options={statusItems}
                  value={
                    formik.values.activeStatus
                      ? statusItems.find((item) => {
                          if (item.value === formik.values.activeStatus)
                            return true
                          return false
                        })
                      : statusItems[0]
                  }
                  error={
                    formik.errors.activeStatus && formik.submitCount > 0
                      ? true
                      : false
                  }
                  onChange={(option) => {
                    formik.setFieldValue("activeStatus", option?.value ?? "")
                  }}
                  placeholder="Укажите активность"
                />
              </Box>
            </Box>
          ) : (
            <>
              <FTLTabs
                value={tabIndex}
                onChange={(_, newValue) => {
                  setTabIndexCallback(newValue)
                }}
              >
                <FTLTab label="Основные" />
                <FTLTab label="Конфигурация" />
                <FTLTab label="Аккаунты" />
              </FTLTabs>
              <Suspense fallback={<FTLLoader height="50vh" />}>
                <Box mt={6}>
                  {tabIndex === 0 && (
                    <MainSettingsTab
                      formik={formik}
                      isFormNotChanged={isFormNotChanged}
                    />
                  )}
                  {tabIndex === 1 && <ConfigurationTab />}
                  {tabIndex === 2 && <AccountTab />}
                </Box>
              </Suspense>
            </>
          )}
        </form>
      )}
       
    </FTLDetailPage>
  )
}

export default DetailExternalServicesPage
