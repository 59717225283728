import { NormalizeAllArgs } from "../../../types/entities"
import { BasicEntity, Dictionary, SelectOption } from "ftl-core"

export function checkFilters<ItemType extends BasicEntity>(
  item: ItemType,
  filters: any
) {
  if (filters && Object.keys(filters).length !== 0) {
    return Object.keys(filters).every((filterKey) => {
      return (
        filters[filterKey] === item[filterKey as keyof ItemType] ||
        !filters[filterKey]
      )
    })
  }
  return true
}

export function basicNormalizeAll<T extends BasicEntity & { name: string }>({
  items,
  setItems,
  labelProp = "name",
}: NormalizeAllArgs<T>) {
  const normalizedLabels: Dictionary<string> = {}
  const normalizedOptions: SelectOption[] = []
  items.forEach((item: T) => {
    if (item[labelProp] && item.id) {
      normalizedLabels[item.id] = "" + item[labelProp]
      normalizedOptions.push({
        value: item.id,
        label: "" + item[labelProp],
      })
    }
  })
  setItems && setItems({ options: normalizedOptions, labels: normalizedLabels })
  return normalizedLabels
}
