import { Dictionary, requiredMessage } from "ftl-core"
import * as Yup from "yup"
import { ExternalServiceProperty } from "../../../types/entities"

export const initState: ExternalServiceProperty<"FORMIK"> = {
  activeStatus: "ACTIVE",
  isRequired: false,
  localized: "",
  localizedHelper: "",
  name: "",
  type: "DOUBLE",
  id: "",
}

export const typeLabels: Dictionary<string> = {
  STRING: "Строка",
  BOOLEAN: "Логический тип",
  DOUBLE: "Число с плавающей запятой",
  LONG: "Целое число",
  ARRAY: "Массив",
}
export const typeItems = Object.keys(typeLabels).map((key) => {
  return {
    value: key,
    label: typeLabels[key],
  }
})

export const ExternalServicePropertySchema = Yup.object({
  name: Yup.string().required(requiredMessage),
  localized: Yup.string().required(requiredMessage),
  type: Yup.string().required(requiredMessage),
})
