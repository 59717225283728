import { useFormik } from "formik"
import { FTLChip, FTLPageHeader, FTLTextField, FTLToolbar } from "ftl-uikit"
import { useMemo, useState } from "react"
import { Box, InputAdornment } from "@material-ui/core"
import { FTLDetailPage } from "ftl-dashboards-templates"
import { FTLObjectUtils, snackbarService } from "ftl-core"
import { FTLShortcutUtils } from "ftl-core"

const initialValues = {}
const shortcuts = FTLShortcutUtils.shortcuts

FTLShortcutUtils.shortcutKeys.forEach((key) => {
  initialValues[key] = shortcuts[key].current
})

export const UserSettingsPage = () => {
  const [entityData, setEntityData] = useState(initialValues)
  const formik = useFormik({
    initialValues: initialValues,
    validate: (values) => {
      const result = {}
      const objValues = Object.values(values)
      const keys = Object.keys(values)
      for (let i = objValues.length; i > 1; i--) {
        objValues.pop()
        const key = keys[i - 1]
        const value = values[keys[i - 1]]
        if (objValues.includes(value)) result[key] = `${value} уже назначена`
      }
      return result
    },
    onSubmit: (values) => {
      Object.keys(values).forEach((key) => {
        FTLShortcutUtils.setKeyCode(key, values[key])
      })
      formik.setSubmitting(false)
      snackbarService.show({
        variant: "success",
        message: "Настройки горячих клавиш сохранены",
      })
      setEntityData(values)
    },
  })

  const components = useMemo(
    () =>
      Object.keys(formik.values).map((key) => {
        return (
          <FTLTextField
            autoComplete="off"
            name={key}
            label={shortcuts[key].label}
            onBlur={formik.handleBlur}
            onKeyDown={(e) => {
              e.preventDefault()
              e.stopPropagation()
              formik.setFieldValue(key, e.code)
            }}
            error={formik.errors[key] ? true : false}
            value=""
            fullWidth
            type=""
            inputProps={{
              style: { color: "transparent" },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {<FTLChip label={formik.values[key]} />}
                </InputAdornment>
              ),
            }}
          />
        )
      }),
    [formik]
  )

  return (
    <FTLDetailPage
      formik={formik}
      headerComponent={<FTLPageHeader title="Персональные настройки" />}
      toolbarComponent={
        <FTLToolbar
          onSaveBtnId="form"
          async={formik.isSubmitting}
          disabled={FTLObjectUtils.compare(entityData, formik.values)}
          onSaveMessage="Сохранить"
        />
      }
    >
      <form
        id="form"
        onSubmit={(e) => {
          e.preventDefault()
          formik.handleSubmit()
        }}
      >
        <Box mt={6} display="grid" gridTemplateColumns="360px" gridGap="24px">
          {components}
        </Box>
      </form>
    </FTLDetailPage>
  )
}

export default UserSettingsPage
