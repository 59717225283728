import ReactDOM from "react-dom"
import App from "./App"
import * as Sentry from "@sentry/browser"
import { Integrations } from "@sentry/tracing"
import { registerSW } from "ftl-core"

Sentry.init({
  dsn:
    process.env.REACT_APP_ENV === "dev"
      ? "https://2f0e06a613024bbda49909d7fb82b761@o495931.ingest.sentry.io/5569515"
      : "https://d5db8a903e3a449dac084bb18cc0dd25@o502989.ingest.sentry.io/5587427",
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_ENV,
  tracesSampleRate: 1.0,
})

ReactDOM.render(<App />, document.getElementById("root"))

registerSW("/service-worker.js")
