import * as Yup from "yup"
import { User } from "../../types/entities"
import {
  FiltersState,
  handleError,
  PhoneNumberFormat,
  requiredMessage,
  snackbarService,
  UseListPageProps,
} from "ftl-core"
import { FTLPhoneUtils } from "ftl-core"
import { UtilsAPI } from "../../repository"

const normalizePhoneRegex = /[-()_\s+]/gm
export const userSchemaConstructor = (
  phoneFormat: PhoneNumberFormat | null,
  validatePassword: boolean
) => {
  const regex = FTLPhoneUtils.regExpConstructor(phoneFormat)
  const maskLength =
    phoneFormat?.mask.replaceAll(normalizePhoneRegex, "").length ?? 0
  return Yup.object({
    firstName: Yup.string().required(requiredMessage),
    lastName: Yup.string().required(requiredMessage),
    roleIds: Yup.array()
      .required(requiredMessage)
      .min(1, "Выберите хотя бы одну роль пользователя"),
    email: Yup.string()
      .email("Введите действующий email")
      .required(requiredMessage),
    password: validatePassword
      ? Yup.string()
          .required(requiredMessage)
          .oneOf([Yup.ref("repeatPassword")], "Пароли должны совпадать")
      : Yup.string().notRequired(),
    phoneNumber: Yup.string()
      .transform((value: string) => value.replaceAll(normalizePhoneRegex, ""))
      .matches(regex, FTLPhoneUtils.matchConstructor(phoneFormat))
      .min(maskLength, "Номер слишком короткий"),
  })
}

export const initState: User<"FORMIK"> = {
  firstName: "",
  secondName: "",
  lastName: "",
  phoneNumber: "",
  countryId: "",
  email: "",
  password: "",
  repeatPassword: "",
  profileImageUrl: "",
  accountStatus: "ACTIVE",
  roleIds: [],
  roleNames: [],
}

//List

export const normalizeUsersFilters = (filters: FiltersState) => ({
  query: filters.query,
  accountStatus: filters.accountStatus?.value,
  roleId: filters.role?.value,
})

export const onSubmitUsersDialog = async (
  id: string,
  onChangeActiveStatus: () => void
) => {
  try {
    await UtilsAPI.switchActiveStatus("users", id)
    onChangeActiveStatus()
    snackbarService.show({
      variant: "success",
      message: "Активность пользователя успешно изменена",
    })
  } catch (error) {
    handleError({
      error,
      defaultError: "Произошла ошибка при изменении активности",
    })
  }
}

export const normalizeUsers: UseListPageProps<
  User<"GET">
>["normalizeResponse"] = ({ items }, context) => {
  const normalized: User<"GET">[] = items.map((item) => {
    return {
      ...item,
      roleNames: item.roleIds.map((id) => context.meta[id]),
    }
  })
  return normalized
}
