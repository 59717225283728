import { lazy, Suspense, useCallback, useEffect, useRef, useState } from "react"

//components
import { FTLDetailPage } from "ftl-dashboards-templates"
//hooks
import { FTLBreadcrumbs, FTLLoader, FTLPageHeader, FTLToolbar } from "ftl-uikit"
import {
  Country,
  DetailPageProps,
  handleError,
  snackbarService,
  useFetchDefaults,
  useTabs,
} from "ftl-core"

//types
import { PhoneNumberFormat } from "ftl-core"

//other
import { userSchemaConstructor, initState } from "./model"
import { useDetailPage } from "ftl-core"
import { FTLTab, FTLTabs } from "ftl-uikit"
import { MetaEntityState, User } from "../../types/entities"
import { fetchAllRoles } from "../../shared/util/entities/roles"
import { CountryAPI, PictureAPI, UsersAPI, UtilsAPI } from "../../repository"

const PersonalDataTab = lazy(() => import("./layout/PersonalDataTab"))
const ContactDataTab = lazy(() => import("./layout/ContactDataTab"))
const SecurityTab = lazy(() => import("./layout/SecurityTab"))

export const DetailUserPage = ({ pageType }: DetailPageProps) => {
  const [roles, setRoles] = useState<MetaEntityState | null>(null)
  const [numberFormat, setNumberFormat] = useState<PhoneNumberFormat | null>(
    null
  )
  const [editPassword, setEditPassword] = useState(pageType === "new")
  const [disabled, setDisabled] = useState(false)

  const [pictureFile, setPictureFile] = useState<FormData | null>(null)
  const pictureSetFlag = useRef(false)

  const fetchRoles = useCallback(
    () =>
      fetchAllRoles({
        items: roles,
        setItems: setRoles,
        labelProp: "locale",
      }),
    [roles]
  )

  useEffect(() => {
    if (pageType === "new") fetchRoles()
  }, [])

  const {
    history,
    debouncedIsFetching,
    formik,
    id,
    entityData,
    isFormNotChanged,
    setIsFetching,
    isFetching,
    setEntityData,
  } = useDetailPage({
    pageType,
    initialValues: initState,
    validationSchema: userSchemaConstructor(numberFormat, editPassword),
    omitValues:
      pageType === "edit" ? ["password", "repeatPassword"] : undefined,
    getDetails: UsersAPI.getById,
    handleFetchedEntity: (user) => {
      handleFetchedUser(user.data.result)
    },
    onSubmit: (values) => {
      onSubmitUser(values)
    },
  })

  const handleUserSubmit = async (values: User | User<"PATCH">) => {
    setIsFetching(true)
    formik.setSubmitting(true)
    try {
      setDisabled(true)
      switch (pageType) {
        case "new":
          await UsersAPI.create(values as User)
          snackbarService.show({
            variant: "success",
            message: "Пользователь успешно создан",
          })
          break
        case "edit":
          await UsersAPI.edit(values as User<"PATCH">)
          snackbarService.show({
            variant: "success",
            message: "Параметры пользователя успешно обновлены",
          })
          break
      }
      history.push("/users")
    } catch (error) {
      setDisabled(false)
      handleError({
        error,
        defaultError: "Произошла ошибка при отправке данных",
      })
    } finally {
      setIsFetching(false)
      formik.setSubmitting(false)
    }
  }

  const handleFetchedUser = async (user: User<"GET">) => {
    let data: User<"FORMIK"> = {
      ...formik.values,
      ...user,
      phoneNumber: "",
      password: "",
      repeatPassword: "",
    }
    try {
      const country: Country<"GET"> = user.countryId
        ? (await CountryAPI.getById(user.countryId)).data.result
        : (await UtilsAPI.getDefault("countries")).data.result
      const roleLabels = await fetchRoles()

      data = {
        ...data,
        countryId: country.id,
        roleNames: user.roleIds.map((id: any) => roleLabels[id]),
      }
      if (country.phoneNumberFormat) {
        setNumberFormat(country.phoneNumberFormat)

        if (user.phoneNumber) {
          data.phoneNumber = user.phoneNumber.replace(
            country.phoneNumberFormat.code + " ",
            ""
          )
        }
      } else {
        data = {
          ...data,
          countryId: "",
          phoneNumber: "",
        }
      }
    } catch (error) {
      data = {
        ...data,
        countryId: "",
        phoneNumber: "",
      }
      handleError({
        error,
        defaultError: "Произошла ошибка при получении данных страны",
      })
    } finally {
      formik.setValues(data, false)
      setEntityData(data)
      setIsFetching(false)
    }
  }

  const onSubmitUser = async (values: User<"POST"> | User<"PATCH">) => {
    setIsFetching(true)
    formik.setSubmitting(true)
    let newValues =
      values.phoneNumber && numberFormat
        ? {
            ...values,
            phoneNumber: `${numberFormat.code} ${values.phoneNumber}`,
          }
        : values
    if (pictureFile) {
      try {
        const profileImageId = (await PictureAPI.post(pictureFile)).data.result
        handleUserSubmit({
          ...newValues,
          profileImageId,
        })
      } catch (error) {
        handleError({
          error,
          defaultError: "Произошла ошибка при загрузке изображения",
        })
        formik.setSubmitting(false)
      }
    } else if (values.id) {
      try {
        if (pictureSetFlag.current) await UsersAPI.deleteAvatar(values.id)
        handleUserSubmit({
          ...newValues,
        })
      } catch (error) {
        handleError({
          error,
          defaultError: "Произошла ошибка при удалении изображения",
        })
        formik.setSubmitting(false)
      }
    } else {
      handleUserSubmit(newValues)
    }
    setIsFetching(false)
    formik.setSubmitting(false)
  }

  const { tabIndex, setTabIndex, goToNextTab, goToPrevTab } = useTabs(0, 2)

  useEffect(() => {
    if (formik.isSubmitting)
      if (
        formik.errors.firstName ||
        formik.errors.lastName ||
        formik.errors.accountStatus ||
        formik.errors.roleIds
      )
        setTabIndex(0)
      else if (
        formik.errors.countryId ||
        formik.errors.phoneNumber ||
        formik.errors.email
      )
        setTabIndex(1)
      else if (formik.errors.password) setTabIndex(2)
  }, [formik.errors, formik.isSubmitting])

  useFetchDefaults({
    needToFetch: pageType === "new",
    defaults: ["countries"],
    getDefault: UtilsAPI.getDefault,
    handleDefaults: (defaults) => {
      if (defaults[0]) {
        formik.setFieldValue("countryId", defaults[0].id, false)
        setNumberFormat(defaults[0].phoneNumberFormat)
      }
      setEntityData(formik.values)
    },
  })

  return (
    <FTLDetailPage<User<"FORMIK">>
      formik={formik}
      headerComponent={
        <>
          <FTLBreadcrumbs
            items={[
              { label: "Пользователи", to: "/users" },
              {
                label:
                  pageType === "edit"
                    ? "Параметры пользователя системы"
                    : "Новый пользователь системы",
                to: "#",
              },
            ]}
          />
          {
            <FTLPageHeader
              title={
                pageType === "edit"
                  ? "Параметры пользователя системы"
                  : "Новый пользователь системы"
              }
              BoxProps={{
                mb: 6,
              }}
            />
          }
        </>
      }
      toolbarComponent={
        <FTLToolbar
          position="sticky"
          onSaveBtnId="form"
          onCancel={() => history.push("/users")}
          async={formik.isSubmitting}
          disabled={
            isFormNotChanged || formik.isSubmitting || isFetching || disabled
          }
          onSaveMessage={pageType === "edit" ? "Сохранить" : "Создать"}
          currentTabIndex={tabIndex}
          goToNextTab={goToNextTab}
          goToPrevTab={goToPrevTab}
        />
      }
    >
      {debouncedIsFetching || formik.isSubmitting || isFetching || disabled ? (
        <FTLLoader height="50vh" />
      ) : (
        <>
          <FTLTabs
            value={tabIndex}
            onChange={(_, newValue) => {
              setTabIndex(newValue)
            }}
          >
            <FTLTab label="Личные данные" />
            <FTLTab label="Контактные данные" />
            <FTLTab label="Безопасность" />
          </FTLTabs>

          <form
            noValidate
            id="form"
            onSubmit={(e) => {
              e.preventDefault()
              formik.handleSubmit()
            }}
          >
            <Suspense fallback={<FTLLoader mt={30} color="dark" />}>
              {tabIndex === 0 && (
                <PersonalDataTab
                  formik={formik}
                  pictureSetFlag={pictureSetFlag}
                  setPictureFile={setPictureFile}
                />
              )}
              {tabIndex === 1 && (
                <ContactDataTab
                  formik={formik}
                  numberFormat={numberFormat}
                  setNumberFormat={setNumberFormat}
                />
              )}
              {tabIndex === 2 && (
                <SecurityTab
                  formik={formik}
                  pageType={pageType}
                  id={id}
                  editPassword={editPassword}
                  setEditPassword={setEditPassword}
                  entityData={entityData}
                  setEntityData={setEntityData}
                />
              )}
            </Suspense>
          </form>
        </>
      )}
    </FTLDetailPage>
  )
}

export default DetailUserPage
