//components
import { Box } from "@material-ui/core"
import {
  FTLBreadcrumbs,
  FTLCheckbox,
  FTLLoader,
  FTLPageHeader,
  FTLToolbar,
} from "ftl-uikit"
import { FTLSelect } from "ftl-uikit"
import { FTLTextField } from "ftl-uikit"

//other
import { initState, typeItems, ExternalServicePropertySchema } from "./model"

import { handleError, useDetailPage } from "ftl-core"
import { snackbarService } from "ftl-core"
import { ExternalServiceDetailPageProps, listTitleToType } from "../model"
import { ExternalServiceProperty } from "../../../types/entities"
import { ExternalServicePropertiesAPI } from "../../../repository"
import { FTLDetailPage } from "ftl-dashboards-templates"
import { HistoryDetailState } from "ftl-core"

export const DetailExternalServiceProperty = ({
  pageType,
  type = "ORDER_SYNC",
}: ExternalServiceDetailPageProps) => {
  const baseUrl = `/${window.location.pathname.split("/")[1]}`
  const {
    isFormNotChanged,
    debouncedIsFetching,
    formik,
    history,
    id,
    setIsFetching,
    isFetching,
    setEntityData,
  } = useDetailPage<
    ExternalServiceProperty<"GET">,
    ExternalServiceProperty<"FORMIK">,
    HistoryDetailState<ExternalServiceProperty<"FORMIK">> & {
      tabIndex?: number
    }
  >({
    pageType,
    initialValues: initState,
    validationSchema: ExternalServicePropertySchema,
    getDetails: ExternalServicePropertiesAPI.getById,
    handleFetchedEntity: async (externalServiceProperty) => {
      formik.setValues(externalServiceProperty.data.result, false)
      setEntityData(externalServiceProperty.data.result)
      setIsFetching(false)
    },
    onSubmit: async (
      values: ExternalServiceProperty | ExternalServiceProperty<"PATCH">
    ) => {
      setIsFetching(true)
      formik.setSubmitting(true)
      if (id) {
        try {
          switch (pageType) {
            case "new":
              await ExternalServicePropertiesAPI.create(
                id,
                values as ExternalServiceProperty
              )
              snackbarService.show({
                variant: "success",
                message: "Свойство внешнего сервиса успешно создано",
              })
              break
            case "edit":
              await ExternalServicePropertiesAPI.edit(
                id,
                values as ExternalServiceProperty<"PATCH">
              )
              snackbarService.show({
                variant: "success",
                message: "Параметры свойства успешно обновлены",
              })

              break
          }
          history.push(`${baseUrl}/${id}`, { tabIndex: 1 })
        } catch (error) {
          handleError({
            error,
            defaultError: "Произошла ошибка при отправке данных",
          })
        } finally {
          formik.setSubmitting(false)
          setIsFetching(false)
          setEntityData(values)
        }
      }
    },
  })

  return (
    <FTLDetailPage<ExternalServiceProperty<"FORMIK">>
      formik={formik}
      headerComponent={
        <>
          <FTLBreadcrumbs
            items={[
              { label: listTitleToType[type], to: baseUrl },
              {
                label: "Параметры внешнего сервиса",
                to: `${baseUrl}/${id}`,
              },
              {
                label:
                  pageType === "new"
                    ? "Новый параметр"
                    : "Редактирование параметра",
                to: "#",
              },
            ]}
          />

          <FTLPageHeader
            title={
              pageType === "new" ? "Новый параметр" : "Редактирование параметра"
            }
            BoxProps={{
              mb: 6,
            }}
          />
        </>
      }
      toolbarComponent={
        <FTLToolbar
          position="sticky"
          onSaveBtnId="form"
          onCancel={() => history.push(`${baseUrl}/${id}`, { tabIndex: 1 })}
          async={formik.isSubmitting}
          disabled={isFormNotChanged || formik.isSubmitting || isFetching}
          onSaveMessage={pageType === "edit" ? "Сохранить" : "Создать"}
        />
      }
    >
      {debouncedIsFetching || formik.isSubmitting || isFetching ? (
        <FTLLoader height="50vh" />
      ) : (
        <form
          noValidate
          id="form"
          onSubmit={(e) => {
            e.preventDefault()
            formik.handleSubmit()
          }}
        >
          <Box display="grid" gridGap="24px" width="360px">
            <FTLTextField
              required
              name="name"
              label="Имя атрибута для взаимодействия с внешней системой"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={
                formik.errors.name &&
                formik.submitCount > 0 &&
                formik.touched.name
                  ? true
                  : false
              }
              onBlur={formik.handleBlur}
              fullWidth
            />

            <FTLTextField
              required
              name="localized"
              label="Наименование для отображения"
              value={formik.values.localized}
              onChange={formik.handleChange}
              error={
                formik.errors.localized &&
                formik.submitCount > 0 &&
                formik.touched.localized
                  ? true
                  : false
              }
              onBlur={formik.handleBlur}
              fullWidth
            />

            <FTLTextField
              name="localizedHelper"
              label="Подсказка для поля ввода"
              value={formik.values.localizedHelper}
              onChange={formik.handleChange}
              error={
                formik.errors.localizedHelper &&
                formik.submitCount > 0 &&
                formik.touched.localizedHelper
                  ? true
                  : false
              }
              onBlur={formik.handleBlur}
              fullWidth
            />

            <FTLSelect
              required
              label="Тип атрибута"
              options={typeItems}
              value={
                formik.values.type
                  ? typeItems.find((item) => {
                      if (item.value === formik.values.type) return true
                      return false
                    })
                  : null
              }
              error={
                formik.errors.type && formik.submitCount > 0 ? true : false
              }
              onChange={(option) => {
                formik.setFieldValue(
                  "type",
                  (option as { value: string })?.value
                )
              }}
              placeholder="Укажите тип атрибута"
            />
            <Box mt={4}>
              <FTLCheckbox
                label="Не отображать при настройке новых аккаунтов"
                onChange={(e) => {
                  if (!e.target.checked) {
                    formik.setFieldValue("activeStatus", "ACTIVE")
                  } else {
                    formik.setFieldValue("activeStatus", "ARCHIVE")
                  }
                }}
                FormControlLabelProps={{
                  style: {
                    marginBottom: 24,
                  },
                }}
                checked={formik.values.activeStatus === "ARCHIVE"}
                size="large"
              />
              <FTLCheckbox
                label="Обязательное поле"
                onChange={(e) => {
                  formik.setFieldValue("isRequired", e.target.checked)
                }}
                checked={formik.values.isRequired}
                size="large"
              />
            </Box>
          </Box>
        </form>
      )}
    </FTLDetailPage>
  )
}

export default DetailExternalServiceProperty
