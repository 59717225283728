import {
  BubbleIcon,
  FTLLogoIcon,
  GearIcon,
  NotificationsIcon,
  OrderServiceIcon,
  PeopleIcon,
  theme,
} from "ftl-uikit"
import { UIMain } from "ftl-dashboards-templates"
import { Routes } from "./Routes"

import { AuthAPI, ProfileAPI } from "./repository"
import {
  basicReducer,
  handleError,
  initialState,
  logOutAction,
  setProfileAction,
} from "ftl-core"
import { createStore } from "redux"
import { useMediaQuery } from "@material-ui/core"

const store = createStore(
  basicReducer,
  initialState,
  //@ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export const App = () => {
  const mobile = useMediaQuery("(hover: none)")
  return (
    <UIMain
      redirectOnAuthUrl="/users"
      project={{
        title: {
          authPage: "Войти в FDelivery Tech Admin",
          header: "FDelivery Tech Admin",
        },
        logo: {
          authPage: <FTLLogoIcon baseColor="#DE2222" />,
          header: <FTLLogoIcon baseColor="#DE2222" sizeRatio={0.6} />,
        },
      }}
      AuthClient={AuthAPI}
      StoreProviderProps={{
        store,
        getProfile: ProfileAPI.get,
      }}
      theme={theme}
      BaseLayoutProps={{
        onMenuClick: async () => {
          if (!store.getState().globalStore.profile.id)
            try {
              const profile = (await ProfileAPI.get()).data.result
              store.dispatch(setProfileAction(profile))
            } catch (error) {
              handleError({
                error,
                defaultError: "Произошла ошибка при получении данных профиля",
              })
            }
        },
        onSignOut: () => store.dispatch(logOutAction),
        profileMenuItems: !mobile
          ? [
              {
                label: "Настройки",
                to: "/settings/user",
              },
            ]
          : undefined,
        sideMenuItems: [
          {
            icon: <BubbleIcon />,
            label: "SMS-сервисы",
            to: "/sms-services",
          },
          {
            icon: <NotificationsIcon />,
            label: "Push-сервисы",
            to: "/push-services",
          },
          {
            icon: <OrderServiceIcon />,
            label: "Сервисы заказов",
            to: "/order-services",
          },
          {
            icon: <PeopleIcon />,
            label: "Пользователи",
            to: "/users",
          },
          {
            icon: <GearIcon />,
            label: "Параметры системы",
            to: "/settings/system",
          },
        ],
      }}
    >
      <Routes />
    </UIMain>
  )
}

export default App
