import { useEffect, useMemo } from "react"

//ftl api
import {
  EditIcon,
  FTLIconButton,
  FTLPageHeader,
  FTLStatusTag,
  FTLTable,
  LockIcon,
} from "ftl-uikit"
import {
  useListPage,
  FTLObjectUtils,
  useLocalStoreState,
  FTLDateUtils,
  dialogService,
  FTLRequestUtils,
  FiltersState,
} from "ftl-core"
//other
import {
  statusLabels,
  statusItems,
  listTitleToType,
  normalizeServicesFilters,
} from "./model"
import { Column } from "react-table"

//types
import { ExternalService } from "../../types/entities"
import { FTLListPage } from "ftl-dashboards-templates"
import { ExternalServicesAPI, UtilsAPI } from "../../repository"
import { Box } from "@material-ui/core"
import { localTimeZoneOffset } from "../../shared/util/localTimeZoneOffset"

export const ExternalServicesList = ({
  type = "ORDER_SYNC",
}: Pick<ExternalService, "type">) => {
  const baseUrl = window.location.pathname.split("/")[1]

  const [filters, tableSettings, setFilters, setTableSettings] =
    useLocalStoreState<FiltersState, ExternalService<"GET">>(type)

  const {
    data,
    pageCount,
    isFetching,
    query,
    history,
    setPageCount,
    setQuery,
    setData,
  } = useListPage<ExternalService<"GET">>(
    {
      filters,
      tableSettings,
      setFilters,
      getList: (params) => ExternalServicesAPI.getList({ ...params, type }),
      normalizeFilters: normalizeServicesFilters,
    },
    [filters, tableSettings, type]
  )

  useEffect(() => {
    setData([])
    setPageCount(0)
  }, [type])

  const columns: Column<ExternalService<"GET">>[] = useMemo(
    () => [
      {
        Header: "Наименование",
        accessor: "name",
        width: "100%",
        minWidth: 200,
        disableSortBy: true,
      },
      {
        Header: "Активность",
        accessor: "activeStatus",
        width: 180,
        disableSortBy: true,
        Cell: (cell) => {
          const value = cell.value
          return (
            value && (
              <FTLStatusTag
                status={value === "ACTIVE" ? "success" : "error"}
                style={{
                  display: "inline-block",
                }}
              >
                {statusLabels[value]}
              </FTLStatusTag>
            )
          )
        },
      },
      {
        Header: "Дата создания",
        width: 150,
        accessor: "createdAt",
        align: "right",
        reverse: true,
        Cell: (cell) => {
          const value = cell.value

          return value
            ? FTLDateUtils.format(
                FTLDateUtils.getTimeZonedDate(localTimeZoneOffset, value)
              )
            : "—"
        },
      },
      {
        Header: "",
        width: 88,
        accessor: "action",
        disableSortBy: true,
        Cell: (cell) => {
          const item = cell.row.original
          const index = cell.row.index
          const active = item.activeStatus === "ACTIVE" ? true : false
          return (
            <Box display="flex" justifyContent="center" alignItems="center">
              <FTLIconButton
                onClick={(e) => {
                  e.stopPropagation()
                  dialogService.show({
                    onSubmit: () => {
                      FTLRequestUtils.createRequest(
                        UtilsAPI.switchActiveStatus(
                          "external-services",
                          item.id
                        ),
                        {
                          successMessage: "Активность сервиса успешно изменена",
                          defaultErrorMessage:
                            "Произошла ошибка при изменении активности",
                          onSuccess: () => {
                            const newItems = data.slice()
                            newItems[index].activeStatus = active
                              ? "ARCHIVE"
                              : "ACTIVE"

                            setData(newItems)
                          },
                        }
                      )
                      dialogService.close()
                    },
                    children: active
                      ? "Вы действительно хотите заблокировать этот сервис?"
                      : "Вы действительно хотите разблокировать этот сервис?",
                  })
                }}
              >
                <LockIcon open={active} />
              </FTLIconButton>
              <FTLIconButton>
                <EditIcon />
              </FTLIconButton>
            </Box>
          )
        },
      },
    ],
    [data, setData]
  )

  return (
    <FTLListPage
      headerComponent={
        <FTLPageHeader
          title={listTitleToType[type]}
          buttons={[
            {
              children: "Новый сервис",
              onClick: () => history.push(`${baseUrl}/new`),
            },
          ]}
        />
      }
      tableComponent={
        <FTLTable
          data={data}
          columns={columns}
          pageCount={pageCount}
          isFetching={isFetching}
          actionsSize={2}
          tableState={tableSettings}
          setTableState={setTableSettings}
          onRowClick={(row) => {
            const data = {
              ...row.original,
              action: undefined,
            }
            history.push(`${baseUrl}/${row.original.id}`, {
              data,
            })
          }}
          rowDisable={(row) => {
            if (row.original.activeStatus === "ARCHIVE") return true
            return false
          }}
          isSearching={Boolean(FTLObjectUtils.getNotEmptyFieldsCount(filters))}
        />
      }
      searchProps={{
        value: query,
        onChange: setQuery,
        placeholder: "Наименование",
      }}
      filters={[
        {
          searchable: false,
          placeholder: "Активность",
          options: statusItems,
          onChange: (option) => {
            setFilters({ activeStatus: option })
          },
          value: filters.activeStatus,
        },
      ]}
    />
  )
}

export default ExternalServicesList
