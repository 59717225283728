import { useCallback, useState, useMemo } from "react"

//components
import { Box } from "@material-ui/core"
import {
  EditIcon,
  FTLIconButton,
  FTLPageHeader,
  FTLStatusTag,
  FTLTable,
  LockIcon,
} from "ftl-uikit"

import {
  accountStatusItems,
  accountStatusLabels,
  useListPage,
  FTLObjectUtils,
  useLocalStoreState,
  FiltersState,
  dialogService,
  FTLRequestUtils,
  FTLDateUtils,
} from "ftl-core"

//types
import { MetaEntityState, User, UserRequest } from "../../types/entities"
import { Column } from "react-table"

//other
import { fetchAllRoles } from "../../shared/util/entities/roles"
import { FTLListPage } from "ftl-dashboards-templates"
import { normalizeUsers, normalizeUsersFilters } from "./model"
import { UsersAPI, UtilsAPI } from "../../repository"
import { localTimeZoneOffset } from "../../shared/util/localTimeZoneOffset"

export const UsersList = () => {
  //Entities
  const [roles, setRoles] = useState<MetaEntityState | null>(null)

  const fetchRoles = useCallback(
    () =>
      fetchAllRoles({
        items: roles,
        setItems: setRoles,
        labelProp: "locale",
      }),
    [roles]
  )

  const [filters, tableSettings, setFilters, setTableSettings] =
    useLocalStoreState<FiltersState, User<"GET">>("usersList")

  const { data, pageCount, isFetching, query, history, setQuery, setData } =
    useListPage<User<"GET">, UserRequest>(
      {
        filters,
        tableSettings,
        setFilters,
        getList: (request) =>
          UsersAPI.getList({ roleAuthorizationType: "EMAIL", ...request }),
        getMeta: fetchRoles,
        normalizeFilters: normalizeUsersFilters,
        normalizeResponse: normalizeUsers,
      },
      [filters, tableSettings]
    )

  const columns: Column<User<"GET">>[] = useMemo(
    () => [
      {
        Header: "ФИО",
        accessor: "fullName",
        disableSortBy: true,
        width: "20%",
        minWidth: 170,
      },
      {
        Header: "Имя пользователя",
        accessor: "email",
        width: "17.5%",
        minWidth: 170,
        disableSortBy: true,
      },
      {
        Header: "Телефон",
        accessor: "phoneNumber",
        width: "15%",
        minWidth: 160,
        disableSortBy: true,
      },
      {
        Header: "Роли",
        accessor: "roleNames",
        width: "25%",
        minWidth: 230,
        disableSortBy: true,
        Cell: (props) => {
          const roleNames = props.value
          return roleNames && roleNames[0] ? (
            <Box
              display="flex"
              flexWrap="wrap"
              style={{
                opacity: props.row.original.accountStatus === "BLOCK" ? 0.5 : 1,
              }}
            >
              {roleNames.map((name) => (
                <FTLStatusTag
                  status="neutral"
                  style={{
                    marginRight: 4,
                    marginBottom: 4,
                  }}
                >
                  {name}
                </FTLStatusTag>
              ))}
            </Box>
          ) : (
            "—"
          )
        },
      },
      {
        Header: "Доступ в систему",
        accessor: "accountStatus",
        width: "12.5%",
        minWidth: 130,
        disableSortBy: true,
        Cell: (props) => {
          const value = props.value
          return (
            value && (
              <FTLStatusTag status={value === "ACTIVE" ? "success" : "error"}>
                {accountStatusLabels[value]}
              </FTLStatusTag>
            )
          )
        },
      },
      {
        Header: "Создан",
        accessor: "createdAt",
        align: "right",
        width: "10%",
        minWidth: 100,
        type: "date",
        reverse: true,
        Cell: (cell) => {
          const value = cell.value
          return value
            ? FTLDateUtils.format(
                FTLDateUtils.getTimeZonedDate(localTimeZoneOffset, value)
              )
            : "—"
        },
      },
      {
        Header: "",
        width: 88,
        accessor: "action",
        disableSortBy: true,
        Cell: (cell) => {
          const item = cell.row.original
          const index = cell.row.index
          const active = item.accountStatus === "ACTIVE" ? true : false
          return (
            <Box display="flex" justifyContent="center" alignItems="center">
              <FTLIconButton
                onClick={(e) => {
                  e.stopPropagation()
                  dialogService.show({
                    onSubmit: () => {
                      FTLRequestUtils.createRequest(
                        UtilsAPI.switchActiveStatus("users", item.id),
                        {
                          successMessage:
                            "Активность пользователя успешно изменена",
                          defaultErrorMessage:
                            "Произошла ошибка при изменении активности",
                          onSuccess: () => {
                            const newItems = data.slice()
                            newItems[index].accountStatus = active
                              ? "BLOCK"
                              : "ACTIVE"

                            setData(newItems)
                          },
                        }
                      )
                      dialogService.close()
                    },
                    children: active
                      ? "Вы действительно хотите заблокировать эту учётную запись?"
                      : "Вы действительно хотите разблокировать эту учётную запись?",
                  })
                }}
              >
                <LockIcon open={active} />
              </FTLIconButton>
              <FTLIconButton>
                <EditIcon />
              </FTLIconButton>
            </Box>
          )
        },
      },
    ],
    [data, setData]
  )

  return (
    <FTLListPage
      headerComponent={
        <FTLPageHeader
          title="Пользователи системы"
          buttons={[
            {
              children: "Добавить",
              onClick: () => history.push("/users/new"),
            },
          ]}
        />
      }
      tableComponent={
        <FTLTable
          data={data}
          columns={columns}
          pageCount={pageCount}
          isFetching={isFetching}
          actionsSize={2}
          tableState={tableSettings}
          setTableState={setTableSettings}
          onRowClick={(row) => {
            history.push(`/users/${row.original.id}`, {
              data: row.original,
            })
          }}
          rowDisable={(row) => {
            if (row.original.accountStatus === "BLOCK") return true
            return false
          }}
          isSearching={Boolean(FTLObjectUtils.getNotEmptyFieldsCount(filters))}
        />
      }
      filters={[
        {
          disableClearable: false,
          placeholder: "Роль",
          options: roles?.options ?? accountStatusItems,
          onChange: (option) => {
            setFilters({ role: option })
          },
          value: filters.role,
        },
        {
          searchable: false,
          placeholder: "Активность",
          onChange: (option) => {
            setFilters({ accountStatus: option })
          },
          options: accountStatusItems,
          value: filters.accountStatus,
        },
      ]}
      searchProps={{
        value: query,
        onChange: (value) => {
          setQuery(value)
        },
        placeholder: "ФИО или email",
      }}
    />
  )
}

export default UsersList
