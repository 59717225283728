import { requiredMessage } from "ftl-core"
import * as Yup from "yup"
import { ExternalServiceAccount } from "../../../types/entities"

export const initState: ExternalServiceAccount<"FORMIK"> = {
  activeStatus: "ACTIVE",
  brandId: "",
  name: "",
  organizationId: "",
  properties: [],
  type: "ORGANIZATION",
  isDefault: false,
}

export const ExternalServiceAccountSchema = Yup.object({
  name: Yup.string().required(requiredMessage),
  type: Yup.string().required(requiredMessage),
  activeStatus: Yup.string().required(requiredMessage),
})
