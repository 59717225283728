// import {
//   lazy,
//   Suspense,
// } from "react"
import { Switch } from "react-router-dom"
import { Box } from "@material-ui/core"

//Other
import {
  FTLErrorPage,
  // FTLLoader
} from "ftl-uikit"
import { CountryAPI, PictureAPI, ProfileAPI, UtilsAPI } from "./repository"
import { PrivateRoute } from "ftl-core"

import UserSettingsPage from "./pages/Settings/User"
import SystemSettingsPage from "./pages/Settings/System"

import ExternalServicesList from "./pages/Services/List"
import DetailExternalServicesPage from "./pages/Services/Detail"
import DetailExternalServiceAccount from "./pages/Services/Account/Detail"
import DetailExternalServiceProperty from "./pages/Services/Property/Detail"

import { FTLProfilePage } from "ftl-dashboards-templates"

import DetailUserPage from "./pages/Users/Detail"
import UsersList from "./pages/Users/List"

// //Settings
// const UserSettingsPage = lazy(() => import("./pages/Settings/User"))
// const SystemSettings = lazy(() => import("./pages/Settings/System"))

// //Services
// const ExternalServicesList = lazy(() => import("./pages/Services/List"))
// const DetailExternalService = lazy(() => import("./pages/Services/Detail"))
// const DetailExternalServiceAccount = lazy(
//   () => import("./pages/Services/Account/Detail")
// )
// const DetailExternalServiceProperty = lazy(
//   () => import("./pages/Services/Property/Detail")
// )

// //Profile
// const ProfilePage = lazy(
//   () =>
//     import("ftl-dashboards-templates/dist/app/templates/FTLProfilePage/index")
// )

// //Users
// const DetailUserPage = lazy(() => import("./pages/Users/Detail"))
// const UsersList = lazy(() => import("./pages/Users/List"))

export const Routes = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/profile">
        <FTLProfilePage
          getProfile={ProfileAPI.get}
          editProfile={ProfileAPI.edit}
          getCountry={CountryAPI.getById}
          getCountries={CountryAPI.getList}
          getDefaultCountry={() => UtilsAPI.getDefault("countries")}
          deleteProfilePicture={ProfileAPI.deletePicture}
          changeProfilePassword={ProfileAPI.changePassword}
          postPicture={PictureAPI.post}
        />
      </PrivateRoute>

      <PrivateRoute exact path="/sms-services">
        <ExternalServicesList type="SMS" />
      </PrivateRoute>
      <PrivateRoute exact path="/sms-services/new">
        <DetailExternalServicesPage pageType="new" type="SMS" />
      </PrivateRoute>
      <PrivateRoute exact path="/sms-services/:id">
        <DetailExternalServicesPage pageType="edit" type="SMS" />
      </PrivateRoute>
      <PrivateRoute exact path="/sms-services/:id/config/new">
        <DetailExternalServiceProperty pageType="new" type="SMS" />
      </PrivateRoute>
      <PrivateRoute exact path="/sms-services/:id/config/:propertyId">
        <DetailExternalServiceProperty pageType="edit" type="SMS" />
      </PrivateRoute>
      <PrivateRoute exact path="/sms-services/:id/account/new">
        <DetailExternalServiceAccount pageType="new" type="SMS" />
      </PrivateRoute>
      <PrivateRoute exact path="/sms-services/:id/account/:propertyId">
        <DetailExternalServiceAccount pageType="edit" type="SMS" />
      </PrivateRoute>

      <PrivateRoute exact path="/push-services">
        <ExternalServicesList type="PUSH" />
      </PrivateRoute>
      <PrivateRoute exact path="/push-services/new">
        <DetailExternalServicesPage pageType="new" type="PUSH" />
      </PrivateRoute>
      <PrivateRoute exact path="/push-services/:id">
        <DetailExternalServicesPage pageType="edit" type="PUSH" />
      </PrivateRoute>
      <PrivateRoute exact path="/push-services/:id/config/new">
        <DetailExternalServiceProperty pageType="new" type="PUSH" />
      </PrivateRoute>
      <PrivateRoute exact path="/push-services/:id/config/:propertyId">
        <DetailExternalServiceProperty pageType="edit" type="PUSH" />
      </PrivateRoute>
      <PrivateRoute exact path="/push-services/:id/account/new">
        <DetailExternalServiceAccount pageType="new" type="PUSH" />
      </PrivateRoute>
      <PrivateRoute exact path="/push-services/:id/account/:propertyId">
        <DetailExternalServiceAccount pageType="edit" type="PUSH" />
      </PrivateRoute>

      <PrivateRoute exact path="/users">
        <UsersList />
      </PrivateRoute>
      <PrivateRoute exact path="/users/new">
        <DetailUserPage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/users/:id">
        <DetailUserPage pageType="edit" />
      </PrivateRoute>

      {/* OrderServices */}
      <PrivateRoute exact path="/order-services">
        <ExternalServicesList type="ORDER_SYNC" />
      </PrivateRoute>
      <PrivateRoute exact path="/order-services/new">
        <DetailExternalServicesPage pageType="new" type="ORDER_SYNC" />
      </PrivateRoute>
      <PrivateRoute exact path="/order-services/:id">
        <DetailExternalServicesPage pageType="edit" type="ORDER_SYNC" />
      </PrivateRoute>
      <PrivateRoute exact path="/order-services/:id/config/new">
        <DetailExternalServiceProperty pageType="new" type="ORDER_SYNC" />
      </PrivateRoute>
      <PrivateRoute exact path="/order-services/:id/config/:propertyId">
        <DetailExternalServiceProperty pageType="edit" type="ORDER_SYNC" />
      </PrivateRoute>
      <PrivateRoute exact path="/order-services/:id/account/new">
        <DetailExternalServiceAccount pageType="new" type="ORDER_SYNC" />
      </PrivateRoute>
      <PrivateRoute exact path="/order-services/:id/account/:propertyId">
        <DetailExternalServiceAccount pageType="edit" type="ORDER_SYNC" />
      </PrivateRoute>
      <PrivateRoute exact path="/settings/system">
        <Box
          display="flex"
          flexDirection="column"
          style={{
            height: "100%",
          }}
        >
          <SystemSettingsPage />
        </Box>
      </PrivateRoute>
      <PrivateRoute exact path="/settings/user">
        <UserSettingsPage />
      </PrivateRoute>

      <PrivateRoute path={["*", "/404"]}>
        <FTLErrorPage
          title="Страницы не существует"
          message="Наверное, вы перешли по неправильной ссылке."
        />
      </PrivateRoute>
    </Switch>
  )
}
