import {
  // ActivityItem,
  // ActivityKeys,
  Dictionary,
  FiltersState,
  requiredMessage,
} from "ftl-core"
import * as Yup from "yup"
import { DetailPageProps } from "../../types/components"
import { ExternalService } from "../../types/entities"

export type ExternalServiceDetailPageProps = DetailPageProps & {
  type?: ExternalService["type"]
}

export const initState: ExternalService<"FORMIK"> = {
  activeStatus: "ACTIVE",
  name: "",
  baseApiUrl: "",
  microserviceInteractionSettings: {
    path: "",
    type: "HTTP",
  },
  type: "ORDER_SYNC",
}

export const statusLabels: Dictionary<string> = {
  ACTIVE: "Активен",
  ARCHIVE: "Приостановлен",
} as const

export const statusItems = Object.keys(statusLabels).map((key) => {
  return {
    value: key,
    label: statusLabels[key],
  }
})

export const isBooleanLabels: Dictionary<string> = {
  true: "Да",
  false: "Нет",
}
export const isBooleanItems = Object.keys(isBooleanLabels).map((key) => {
  return {
    value: key === "true" ? true : false,
    label: isBooleanLabels[key],
  }
})

export const ExternalServiceSchema = Yup.object({
  name: Yup.string().required(requiredMessage),
  baseApiUrl: Yup.string().required(requiredMessage),
  activeStatus: Yup.string().required(requiredMessage),
})

export const defaultErrorToType: Dictionary<string> = {
  ORDER_SYNC: "Произошла ошибка при получении данных сервиса заказов",
  PUSH: "Произошла ошибка при получении данных push-сервиса",
  SMS: "Произошла ошибка при получении данных sms-сервиса",
}

export const successMessageToType: Dictionary<string> = {
  ORDER_SYNC: "Cервис заказов успешно создан",
  PUSH: "Push-сервис успешно создан",
  SMS: "SMS-сервис успешно создан",
}

export const listTitleToType: Dictionary<string> = {
  ORDER_SYNC: "Параметры сервисов синхронизации заказов",
  PUSH: "Параметры Push-сервисов",
  SMS: "Параметры SMS-сервисов",
}

export const listPageUrlLabelToType: Dictionary<string> = {
  ORDER_SYNC: "Cервисы заказов",
  PUSH: "Push-сервисы",
  SMS: "SMS-сервисы",
}

export const typeLabels: Dictionary<string> = {
  SYSTEM: "Система",
  ORGANIZATION: "Организация",
}

export const typeItems = Object.keys(typeLabels).map((key) => {
  return {
    value: key,
    label: typeLabels[key],
  }
})

//List

export const normalizeServicesFilters = (filters: FiltersState) => ({
  query: filters.query,
  activeStatus: filters.activeStatus?.value,
})
