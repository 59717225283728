import { lazy, useEffect, useState, useRef, Suspense } from "react"
//components
import { Box } from "@material-ui/core"
import {
  FTLBreadcrumbs,
  FTLLoader,
  FTLPageHeader,
  FTLTab,
  FTLTabs,
  FTLToolbar,
} from "ftl-uikit"

//other
import { initState, ExternalServiceAccountSchema } from "./model"
import {
  Dictionary,
  FTLRequestUtils,
  handleError,
  useDetailPage,
  useTabs,
} from "ftl-core"
import { snackbarService } from "ftl-core"
import { ExternalServiceDetailPageProps, listTitleToType } from "../model"
import { ExternalServiceAccount } from "../../../types/entities"
import {
  ExternalServiceAccountsAPI,
  BrandAPI,
  OrganizationAPI,
  ExternalServicePropertiesAPI,
} from "../../../repository"
import { HistoryDetailState } from "ftl-core"
import { FTLDetailPage } from "ftl-dashboards-templates"

const MainTab = lazy(() => import("./layout/MainTab"))
const ConfigTab = lazy(() => import("./layout/ConfigTab"))

export type Properties = {
  activeStatus: string
  id: string
  isRequired: boolean
  localized: string
  localizedHelper: string
  type: string
}

export const DetailExternalServiceAccount = ({
  pageType,
  type = "ORDER_SYNC",
}: ExternalServiceDetailPageProps) => {
  const baseUrl = `/${window.location.pathname.split("/")[1]}`

  const { tabIndex, setTabIndex, goToNextTab, goToPrevTab } = useTabs(0, 1)

  const {
    debouncedIsFetching,
    formik,
    history,
    isFormNotChanged,
    id,
    propertyId,
    isFetching,
    setIsFetching,
    setEntityData,
  } = useDetailPage<
    ExternalServiceAccount<"GET">,
    ExternalServiceAccount<"FORMIK">,
    HistoryDetailState<ExternalServiceAccount<"FORMIK">> & { tabIndex?: number }
  >({
    pageType,
    initialValues: initState,
    validationSchema: ExternalServiceAccountSchema,
    getDetails: ExternalServiceAccountsAPI.getById,
    handleFetchedEntity: async (externalServiceAccount) => {
      setIsFetching(true)
      let data = {
        ...formik.values,
        ...externalServiceAccount.data.result,
      }
      try {
        const [organization, brand] = await Promise.all([
          externalServiceAccount.data.result.organizationId
            ? (
                await OrganizationAPI.getById(
                  externalServiceAccount.data.result.organizationId
                )
              ).data.result
            : undefined,
          externalServiceAccount.data.result.brandId
            ? (
                await BrandAPI.getById(
                  externalServiceAccount.data.result.brandId
                )
              ).data.result
            : undefined,
        ])
        data = {
          ...data,
          organizationName: organization?.name ?? undefined,
          brandName: brand?.name ?? undefined,
        }
      } catch (error) {
        data = {
          ...data,
          brandId: "",
          organizationId: "",
        }
        handleError({
          error,
          defaultError: "Произошла ошибка при получении данных",
        })
      } finally {
        formik.setValues(data, false)
        setEntityData(data)
        setIsFetching(false)
      }
    },
    onSubmit: async (
      values: ExternalServiceAccount | ExternalServiceAccount<"PATCH">
    ) => {
      setIsFetching(true)
      formik.setSubmitting(true)
      if (id) {
        const props = values.properties.filter((property) =>
          Boolean(
            property.value !== undefined &&
              property.value !== null &&
              property.value !== "" &&
              property.propertyId
          )
        )

        if (
          !requiredProperties.current.every((requiredProperty) =>
            props.find((property) =>
              Boolean(
                property.propertyId === requiredProperty.id &&
                  property.value !== undefined &&
                  property.value !== null &&
                  property.value !== ""
              )
            )
          )
        ) {
          formik.setErrors({
            properties: "Пожалуйста, заполните все обязательные поля",
          })
          return
        }
        const newValues = {
          ...values,
          properties: props,
        }
        try {
          switch (pageType) {
            case "new":
              await ExternalServiceAccountsAPI.create(id, newValues)
              snackbarService.show({
                variant: "success",
                message: "Аккаунт успешно создан",
              })
              break
            case "edit":
              await ExternalServiceAccountsAPI.edit(
                id,
                newValues as ExternalServiceAccount<"PATCH">
              )
              snackbarService.show({
                variant: "success",
                message: "Параметры аккаунта успешно обновлены",
              })
              break
          }
          setEntityData(values)
          history.push(`${baseUrl}/${id}`, { tabIndex: 2 })
        } catch (error) {
          handleError({
            error,
            defaultError: "Произошла ошибка при отправке данных",
          })
        } finally {
          setIsFetching(false)
          formik.setSubmitting(false)
        }
      }
    },
  })

  const [properties, setProperties] = useState<Properties[]>([])
  const requiredProperties = useRef<Properties[]>([])

  useEffect(() => {
    if (id && !isFetching) {
      FTLRequestUtils.createRequest(ExternalServicePropertiesAPI.getAll(id), {
        defaultErrorMessage: "Произошла ошибка при получении конфигураций",
        onSuccess: (response) => {
          const properties = response.data.result.items
          setProperties(properties)
          requiredProperties.current = properties.filter(
            (property) => property.isRequired
          )
          const propertyValues: Dictionary<any> = {}

          if (propertyId) {
            formik.values.properties.forEach((property) => {
              propertyValues[property.propertyId] = property.value
            })
          }
          const values = {
            ...formik.values,
            properties: properties.map((property) => ({
              value:
                propertyValues[property.id] !== null &&
                propertyValues[property.id] !== undefined &&
                propertyValues[property.id] !== ""
                  ? propertyValues[property.id]
                  : "",
              propertyId: property.id,
            })),
          }
          formik.setValues(values)
          setEntityData(values)
        },
      })
    }
  }, [isFetching])

  useEffect(() => {
    if (formik.isSubmitting)
      if (
        formik.errors.name ||
        formik.errors.type ||
        formik.errors.activeStatus
      )
        setTabIndex(0)
      else if (formik.errors.properties) setTabIndex(1)
  }, [formik.errors, formik.isSubmitting])

  return (
    <FTLDetailPage
      formik={formik}
      headerComponent={
        <>
          <FTLBreadcrumbs
            items={[
              { label: listTitleToType[type], to: baseUrl },
              {
                label: "Параметры внешнего сервиса",
                to: `${baseUrl}/${id}`,
              },
              {
                label:
                  pageType === "new" ? "Новый аккаунт" : "Параметры аккаунта",
                to: "#",
              },
            ]}
          />

          <FTLPageHeader
            title={pageType === "new" ? "Новый аккаунт" : "Параметры аккаунта"}
            BoxProps={{
              mb: 6,
            }}
          />
        </>
      }
      toolbarComponent={
        <FTLToolbar
          position="sticky"
          onSaveBtnId="form"
          onCancel={() => history.push(`${baseUrl}/${id}`, { tabIndex: 2 })}
          async={formik.isSubmitting}
          disabled={isFormNotChanged || formik.isSubmitting || isFetching}
          onSaveMessage={pageType === "new" ? "Создать" : "Сохранить"}
          goToNextTab={goToNextTab}
          goToPrevTab={goToPrevTab}
          currentTabIndex={tabIndex}
        />
      }
    >
      {debouncedIsFetching || formik.isSubmitting || isFetching ? (
        <FTLLoader height="50vh" />
      ) : (
        <>
          <FTLTabs
            value={tabIndex}
            onChange={(_, newValue) => {
              setTabIndex(newValue)
            }}
          >
            <FTLTab label="Основные" />
            <FTLTab label="Конфигурация" disabled={!properties.length} />
          </FTLTabs>
          <Suspense fallback={<FTLLoader height="50vh" />}>
            <Box mt={6}>
              <form
                noValidate
                id="form"
                onSubmit={(e) => {
                  e.preventDefault()
                  formik.handleSubmit()
                }}
              >
                {tabIndex === 0 && <MainTab formik={formik} />}
                {tabIndex === 1 && (
                  <ConfigTab formik={formik} properties={properties} />
                )}
              </form>
            </Box>
          </Suspense>
        </>
      )}
    </FTLDetailPage>
  )
}

export default DetailExternalServiceAccount
